<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container
        style="width: 90%; max-width: 450px; max-height=90vh;"
        class="mx-auto"
      >
        <v-row class="mt-12">
          <v-flex xs12>
            <v-img
              class="mx-auto"
              src="@/assets/img/logo.gif"
              width="100%"
              max-width="200px"
            ></v-img>
          </v-flex>
        </v-row>
        <v-row>
          <p
            class="font-weight-black text-center fx-20 mx-auto"
            style="font-size:20px; margin-top:12px;"
          >
            {{ $t('systemName') }}
          </p>
        </v-row>
        <v-row>
          <p class="text-center fx-20 mx-auto my-0" style="font-size:16px;">
            {{ $t('description.maintenance') }}
          </p>
        </v-row>
        <v-row>
          <p class="text-center fx-20 mx-auto my-0" style="font-size:16px;">
            {{ $t('description.maintenanceMessage') }}
          </p>
        </v-row>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Maintenance',
  methods: {},
};
</script>
